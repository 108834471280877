import * as yup from "yup";

const handleAdminCreateSubscriptionSchema = yup.object().shape({
  //   firstName: yup.string().required("First Name is required"),
  //   lastName: yup.string().required("Last Name is required"),
  //   email: yup.string().email().required("Email is required"),
  //   password: yup
  //     .string()
  //     .min(8)
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
  //       "Password must include at least one capital letter, one number, and one special symbol. Allowed special symbols is: @, $, !, %, *, ?, &"
  //     )
  //     .required()
  //     .label("Password")
  //     .typeError("Password must be at least 8 characters."),
  //   phoneNumber: yup.string().required("Phone Number is required"),
  //   companyName: yup.string().nullable(true),
  //   country: yup
  //     .number()
  //     .required()
  //     .label("Country")
  //     .typeError("Country must be a number."),
  //   subscription: yup
  //     .string()
  //     .required()
  //     .label("Subscription")
  //     .typeError("Subscription must be a string."),
  //   subsCriptionExpireDate: yup
  //     .string()
  //     .label("Subscription Expire Date")
  //     .typeError("Subscription expire date must be a string.")
  //     .nullable(true)
  //     .default(""),
  //   companyRegistrationSelection: yup
  //     .number()
  //     .required()
  //     .label("Company Registration Selection")
  //     .typeError("Company registration selection must be a number."),
  //   organizationId: yup
  //     .number()
  //     .label("Organization ID")
  //     .typeError("Organization ID must be a number.")
  //     .nullable(true),
});

export default handleAdminCreateSubscriptionSchema;
