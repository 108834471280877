import React, { useState } from "react";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
  Select,
} from "components/Forms";
import "./style.css";
import notify from "utils/toastMsg";
import handleAdminCreateSubscriptionSchema from "./schemas/handleAdminCreateSubscriptionSchema";
import SUBSCRIPTION_OPTIONS from "constants/subscriptionOptions";
import postSubscriptions from "services/postSubscriptions";
import putUsers from "services/putUsers";

const AdminCreateSubscription = ({
  setShowAdminCreateSubscription,
  setUsersRefreshKey,
  userRecord,
}) => {
  const [subscribedProduct, setSubscribedProduct] = useState(
    SUBSCRIPTION_OPTIONS[0]
  );
  const [loading, setLoading] = useState(false);

  const postAdminNewSubscriptionCreate = async (values) => {
    setLoading(true);
    try {
      const {
        data: { insertedSubscriptionId },
      } = await postSubscriptions({
        subscribedProduct,
        subscriptionExpiryDate: values.subsCriptionExpireDate,
        creditsAllocated: values.creditsAllocated,
        creditsRemaining: values.creditsAllocated,
        source: values.source,
      });
      await putUsers({
        userId: userRecord.user_id,
        subscriptionId: insertedSubscriptionId,
      });
      setUsersRefreshKey((oldKey) => oldKey + 1);
      notify("User Subscription Created Successfully. ✅");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notify("An error occurred while creating the user. ❌");
    } finally {
      setShowAdminCreateSubscription(false);
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-add-new-machine "
      onClick={() => setShowAdminCreateSubscription(false)}
    >
      <div
        className="main-container-pop-add-new-user"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-add-new-user">
          <div className="d-flex justify-content-end align-items-end">
            <i
              onClick={() => setShowAdminCreateSubscription(false)}
              type="button"
              className="mdi mdi-close fs-xl"
            ></i>
          </div>
          <h2 className="d-flex justify-content-center title-add-new-user">
            Subscription Details
          </h2>
          <div className="d-flex justify-content-center flex-column">
            <Form
              initialValues={{
                country: 187,
                companyRegistrationSelection: 1,
              }}
              validationSchema={handleAdminCreateSubscriptionSchema}
              onSubmit={(values) => postAdminNewSubscriptionCreate(values)}
            >
              <div className="d-flex register-wrapper">
                <div className="input-field-wrapper">
                  <div className="form-group">
                    <label className="register-input-title">Subscription</label>
                    <div>
                      <Select
                        name="subscription"
                        type="text"
                        className="form-control form-control-size-add-new-machine register-input"
                        placeholder="Select Subscription Type"
                        options={SUBSCRIPTION_OPTIONS}
                        value={subscribedProduct}
                        onChange={(e) => setSubscribedProduct(e.target.value)}
                      />
                    </div>
                    <ErrorMessage name="subscription" />
                  </div>
                  {subscribedProduct === "FEEDS" ||
                  subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ? (
                    <div>
                      <div className="form-group">
                        <label className="register-input-title">Runs</label>
                        <div>
                          <InputNonOptimized
                            name="creditsAllocated"
                            type="text"
                            className="form-control register-input"
                            placeholder={"Enter Run Count User Will Have"}
                          />
                        </div>
                        <ErrorMessage name="creditsAllocated" />
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label className="register-input-title">
                      Subscription Expire Date
                    </label>
                    <InputNonOptimized
                      type="date"
                      name="subsCriptionExpireDate"
                      className="form-control"
                    />
                    <ErrorMessage name="subsCriptionExpireDate" />
                  </div>
                  <div>
                    <div className="form-group">
                      <label className="register-input-title">Source</label>
                      <div>
                        <InputNonOptimized
                          name="source"
                          type="text"
                          className="form-control register-input"
                          placeholder={"Default: DIRECT"}
                        />
                      </div>
                      <ErrorMessage name="source" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-around">
                <SubmitButton
                  className="btn btn-lg waves-effect waves-light register-button"
                  disabled={loading}
                >
                  Create Subscription
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateSubscription;
