import http from "utils/http";
import { users } from "constants/endpoints";

const putUsers = ({
  userId,
  firstName,
  lastName,
  email,
  jobTitle,
  userTypeId,
  organizationId,
  phoneNumber,
  meta,
  stripeCustomerRef,
  subscriptionId,
  awsUserId,
}) => {
  const ENDPOINT = users.putUsers({ userId });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.put(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      firstName,
      lastName,
      email,
      jobTitle,
      userTypeId,
      organizationId,
      phoneNumber,
      meta,
      stripeCustomerRef,
      subscriptionId,
      awsUserId,
    },
  });
};

export default putUsers;
