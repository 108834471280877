import "./style.css";

const Download = () => {
  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img
          className="login-logo m-2 ml-4"
          src="assets/images/ll4.png"
          alt="logo"
        />
      </a>
      <div className="account-pages m-0" style={{ backgroundColor: "white" }}>
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <img
              className="login-image"
              src="assets/images/SignOnPage.png"
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <div>
              <div>
                <div className="p-1">
                  <h1 className="text-center">Chatter-free Milling</h1>
                  <h2 className="text-center mt-4">Welcome Back</h2>
                </div>
                <button className="btn btn-primary btn-lg login-button">
                  Download Installers for SenseNC
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Download;
