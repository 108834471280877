import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import notify from "utils/toastMsg";
import postCompleteRegistration from "services/postCompleteRegistration";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
} from "components/Forms";
import * as yup from "yup";

const ConfirmSignUp = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const emailVerificationSchema = yup.object().shape({
    userName: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    confirmationCode: yup.string().required("Confirmation code is required"),
  });

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await postCompleteRegistration({
        email: username,
        confirmationCode,
      });
      if (source === "siemens-marketplace") {
        notify(
          "Your account confirmed successfully. Redirecting to download page... ✅ "
        );
      } else {
        notify(
          "Your account confirmed successfully. Redirecting to login page... ✅ "
        );
      }
      setMessage(``);
      setTimeout(() => {
        if (source === "siemens-marketplace") {
          history.push("/download");
        } else {
          history.push("/login");
        }
        // history.push("/login");
      }, 4000);
    } catch (err) {
      setMessage(`Please enter valid confirmation code.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a href="https://productivemachines.co.uk/">
        <img className="login-logo" src="assets/images/ll2.png" alt="logo" />
      </a>
      <div className="row align-items-center" style={{ marginBottom: "5rem" }}>
        <div className="col-">
          <div className="card" style={{ marginTop: "4rem" }}>
            <div className="card-body">
              <div className="p-1 align-items-center">
                <h4 className="register-header">Confirm Your Account</h4>
                <Form
                  initialValues={{}}
                  validationSchema={emailVerificationSchema}
                  onSubmit={handleConfirm}
                >
                  <div className="register-wrapper">
                    <div className="input-field-wrapper">
                      <div className="form-group">
                        <label className="register-input-title">Email</label>
                        <div>
                          <InputNonOptimized
                            name="userName"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="form-control register-input"
                          />
                        </div>
                        <ErrorMessage name="userName" />
                      </div>
                      <div className="form-group">
                        <label className="register-input-title">
                          Confirmation Code
                        </label>
                        <div>
                          <InputNonOptimized
                            name="confirmationCode"
                            type="text"
                            value={confirmationCode}
                            onChange={(e) =>
                              setConfirmationCode(e.target.value)
                            }
                            className="form-control register-input"
                          />
                        </div>
                        <ErrorMessage name="confirmationCode" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {message && <p className="text-danger mb-0">{message}</p>}
                  </div>
                  <div className="mt-4 d-flex justify-content-around">
                    <SubmitButton
                      className="btn btn-lg waves-effect waves-light register-button"
                      disabled={loading}
                    >
                      Confirm Account
                    </SubmitButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmSignUp;
