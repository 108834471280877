import React, { useState } from "react";
import {
  Form,
  InputNonOptimized,
  ErrorMessage,
  SubmitButton,
  Select,
  Radio,
} from "components/Forms";
import "./style.css";
import useAPI from "effects/useAPI";
import notify from "utils/toastMsg";
import handleAdminCreateUserSubmitSchema from "./schemas/handleAdminCreateUserSubmitSchema";
import COUNTRY_OPTIONS from "constants/countryOptions";
import SUBSCRIPTION_OPTIONS from "constants/subscriptionOptions";
import postUser from "services/postUser";
import getOrganizations from "services/getOrganizations";

const AdminCreateUser = ({ setShowAdminCreateUser, setUsersRefreshKey }) => {
  const [companyRegistrationSelection, setCompanyRegistrationSelection] =
    useState(1);

  const [subscribedProduct, setSubscribedProduct] = useState(
    SUBSCRIPTION_OPTIONS[0]
  );

  // eslint-disable-next-line no-unused-vars
  const [organizationsLoading, organizationsError, organizationsResponse] =
    useAPI(() => getOrganizations());

  const { organizations } = organizationsResponse;
  let organizationsOptions = [];

  if (organizations) {
    organizations.map((option) => {
      return organizationsOptions.push({
        key: option.organizationId,
        value: option.organizationId,
        label: `${option.organizationName} | Organization Id: ${option.organizationId}`,
      });
    });
  }

  const companyRegistrationTypeRadioHandler = (value) => {
    switch (value) {
      case 1:
        setCompanyRegistrationSelection(1);
        break;
      case 2:
        setCompanyRegistrationSelection(2);
        break;
      default:
        setCompanyRegistrationSelection(1);
    }
  };

  const postAdminUserCreate = async (values) => {
    try {
      await postUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        phoneNumber: values.phoneNumber,
        organizationName: values.companyName,
        country: values.country,
        subscription: values.subscription,
        creditsAllocated: values.creditsAllocated,
        subsCriptionExpireDate: values.subsCriptionExpireDate,
        companyRegistrationSelection: companyRegistrationSelection,
        organizationId: values.organization,
      });
      setUsersRefreshKey((oldKey) => oldKey + 1);
      setShowAdminCreateUser(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notify("An error occurred while creating the user. ❌");
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-add-new-machine "
      onClick={() => setShowAdminCreateUser(false)}
    >
      <div
        className="main-container-pop-add-new-user"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-add-new-user">
          <div className="d-flex justify-content-end align-items-end">
            <i
              onClick={() => setShowAdminCreateUser(false)}
              type="button"
              className="mdi mdi-close fs-xl"
            ></i>
          </div>
          <h2 className="d-flex justify-content-center title-add-new-user">
            Create User
          </h2>
          <div className="d-flex justify-content-center flex-column">
            <Form
              initialValues={{
                country: 187,
                companyRegistrationSelection: 1,
              }}
              validationSchema={handleAdminCreateUserSubmitSchema}
              onSubmit={(values, actions) =>
                postAdminUserCreate(values, actions)
              }
            >
              <div className="d-flex register-wrapper">
                <div className="input-field-wrapper">
                  <div className="form-group">
                    <label className="register-input-title">First Name</label>
                    <div>
                      <InputNonOptimized
                        name="firstName"
                        type="text"
                        className="form-control register-input"
                        placeholder="Type first name"
                      />
                    </div>
                    <ErrorMessage name="firstName" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Last Name</label>
                    <div>
                      <InputNonOptimized
                        name="lastName"
                        type="text"
                        className="form-control register-input"
                        placeholder="Type last name"
                      />
                    </div>
                    <ErrorMessage name="lastName" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">E-mail</label>
                    <div>
                      <InputNonOptimized
                        name="email"
                        type="email"
                        className="form-control register-input"
                        parsley-type="email"
                        placeholder={"Enter a valid e-mail"}
                      />
                    </div>
                    <ErrorMessage name="email" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Country</label>
                    <div>
                      <Select
                        name="country"
                        type="text"
                        className="form-control form-control-size-add-new-machine register-input"
                        placeholder="Select Country"
                        style={{ width: "19rem" }}
                        options={COUNTRY_OPTIONS}
                      />
                    </div>
                    <ErrorMessage name="country" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Phone Number</label>
                    <div>
                      <InputNonOptimized
                        name="phoneNumber"
                        type="tel"
                        className="form-control register-input"
                        placeholder="Enter phone number"
                      />
                    </div>
                    <ErrorMessage name="phoneNumber" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Company</label>
                    <div
                      className="btn-group btn-group-toggle d-flex flex-column mb-2"
                      data-toggle="buttons"
                    >
                      <Radio
                        name="companyRegisterSelection"
                        onChange={(value) =>
                          companyRegistrationTypeRadioHandler(value)
                        }
                        options={[
                          {
                            value: 1,
                            label: "Create Company",
                          },
                          {
                            value: 2,
                            label: "Select Company",
                          },
                        ]}
                      />
                    </div>
                    {companyRegistrationSelection === 1 ? (
                      <div>
                        <InputNonOptimized
                          name="companyName"
                          type="text"
                          className="form-control register-input"
                          placeholder={"Enter Company Name"}
                        />
                      </div>
                    ) : (
                      <div>
                        <Select
                          name="organization"
                          type="text"
                          className="form-control form-control-size-add-new-machine register-input"
                          placeholder="Select Organization"
                          style={{ width: "19rem" }}
                          options={organizationsOptions}
                        />
                      </div>
                    )}
                    <ErrorMessage name="companyName" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Subscription</label>
                    <div>
                      <Select
                        name="subscription"
                        type="text"
                        className="form-control form-control-size-add-new-machine register-input"
                        placeholder="Select Subscription Type"
                        options={SUBSCRIPTION_OPTIONS}
                        value={subscribedProduct}
                        onChange={(e) => setSubscribedProduct(e.target.value)}
                      />
                    </div>
                    <ErrorMessage name="subscription" />
                  </div>
                  {subscribedProduct === "FEEDS" ||
                  subscribedProduct === "FEEDS AND FEEDRATE OPTIMIZER" ||
                  subscribedProduct === "FEEDS AND ESSENTIAL OPTIMIZER" ? (
                    <div>
                      <div className="form-group">
                        <label className="register-input-title">Runs</label>
                        <div>
                          <InputNonOptimized
                            name="creditsAllocated"
                            type="text"
                            className="form-control register-input"
                            placeholder={"Enter Run Count User Will Have"}
                          />
                        </div>
                        <ErrorMessage name="creditsAllocated" />
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label className="register-input-title">
                      Subscription Expire Date
                    </label>
                    <InputNonOptimized
                      type="date"
                      name="subsCriptionExpireDate"
                      className="form-control"
                    />
                    <ErrorMessage name="subsCriptionExpireDate" />
                  </div>
                  <div className="form-group">
                    <label className="register-input-title">Password</label>
                    <div>
                      <InputNonOptimized
                        name="password"
                        type="text"
                        className="form-control register-input"
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                    </div>
                    <div style={{ width: "19rem" }}>
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-around">
                <SubmitButton className="btn btn-lg waves-effect waves-light register-button">
                  Create An Account
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateUser;
