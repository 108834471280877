import React from "react";
import { Form, Input, ErrorMessage } from "components/Forms";
import Authentication from "services/Authentication";
import { schemaBasicDetails } from "./schemas/userDetailsSchema";
import { useHistory } from "react-router";

const UserProfile = () => {
  let history = useHistory();
  let auth = new Authentication();
  const jwtPayload = auth.getAccessTokenPayload();
  return (
    <div>
      <div style={{ marginTop: 150 }}>
        <div
          className="row cardss"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-xl-8 ">
            <div className="card " style={{ justifyContent: "flex-end" }}>
              <div className="card-body">
                <h4 className="mt-0 header-title mb-4">Basic Details</h4>
                <Form
                  initialValues={{
                    firstName: jwtPayload.firstName,
                    lastName: jwtPayload.lastName,
                    email: jwtPayload.email,
                  }}
                  validationSchema={schemaBasicDetails}
                  onSubmit={() => {}}
                >
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-10">
                      <Input
                        name="firstName"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-10">
                      <Input
                        name="lastName"
                        className="form-control"
                        disabled={true}
                      />
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-email-input"
                      className="col-sm-2 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-10">
                      <Input
                        type="email"
                        name="email"
                        disabled={true}
                        parsley-type="email"
                        className="form-control"
                      />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="row cardss"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-xl-8 ">
            <div className="card " style={{ justifyContent: "flex-end" }}>
              <div className="card-body">
                <h4 className="mt-0 header-title mb-4">Change Password</h4>
                <button
                  className="btn btn-success waves-effect waves-light float-left"
                  onClick={() => {
                    auth.logout();
                    history.push("/recovery-request");
                  }}
                >
                  Go to reset password page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
