const SUBSCRIPTION_OPTIONS = [
  { key: 1, value: "NONE", label: "NONE" },
  { key: 2, value: "FEEDRATE OPTIMIZER", label: "FEEDRATE OPTIMIZER" },
  { key: 3, value: "ESSENTIAL OPTIMIZER", label: "ESSENTIAL OPTIMIZER" },
  { key: 4, value: "PREDICTIVE MAINTENANCE", label: "PREDICTIVE MAINTENANCE" },
  { key: 5, value: "FEEDS", label: "FEEDS" },
  {
    key: 6,
    value: "FEEDS AND FEEDRATE OPTIMIZER",
    label: "FEEDS AND FEEDRATE OPTIMIZER",
  },
  {
    key: 7,
    value: "FEEDS AND ESSENTIAL OPTIMIZER",
    label: "FEEDS AND ESSENTIAL OPTIMIZER",
  },
  { key: 8, value: "FINESSE", label: "FINESSE" },
  {
    key: 9,
    value: "FINESSE AND FEEDRATE OPTIMIZER",
    label: "FINESSE AND FEEDRATE OPTIMIZER",
  },
  {
    key: 10,
    value: "FINESSE AND ESSENTIAL OPTIMIZER",
    label: "FINESSE AND ESSENTIAL OPTIMIZER",
  },
];

export default SUBSCRIPTION_OPTIONS;
