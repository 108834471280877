import { XestTable } from "components/CustomComponents";
import React from "react";
import getUsers from "services/getUsers";

const statusStyle = {
  color: "#fff",
  backgroundColor: "#28a745",
  borderColor: "#28a745",
  display: "inline-block",
  fontWeight: 400,
  textAlign: "center",
  verticalAlign: "middle",
  border: "1px solid transparent",
  padding: ".375rem .75rem",
  fontSize: "1rem",
  lineHeight: "1.5",
  borderRadius: ".25rem",
};

const UsersTable = ({
  usersRefreshKey,
  setShowAdminCreateSubscription,
  setUserRecord,
}) => {
  const columns = [
    {
      dataIndex: "first_name",
      title: "First Name",
      filterType: {
        dbCol: "users.first_name",
        type: "string",
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "last_name",
      title: "Last Name",
      filterType: {
        dbCol: "users.last_name",
        type: "string",
      },
    },
    {
      dataIndex: "email",
      title: "Email",
      filterType: {
        dbCol: "users.email",
        type: "string",
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: "organization_name",
      title: "Organization Name",
      filterType: {
        dbCol: "organizations.organization_name",
        type: "string",
      },
    },
    {
      dataIndex: "subscribed_product",
      title: "Subscribed Product",
      filterType: {
        dbCol: "subscribed_product",
        type: "string",
      },
      render: (record) => {
        return record !== null ? record : "N/A";
      },
    },
    {
      dataIndex: "subscription_status",
      title: "Subscription Status",
      filterType: {
        dbCol: "subscription_status",
        type: "string",
      },
      render: (record) => {
        if (record === "active") {
          return <span style={statusStyle}>{record}</span>;
        }
        return record !== null ? record : "N/A";
      },
    },
    // {
    //   dataIndex: "subscription_type",
    //   title: "Subscription Type",
    //   filterType: {
    //     dbCol: "subscription_type",
    //     type: "string",
    //   },
    //   render: (record) => {
    //     return record !== null ? record : "N/A";
    //   },
    // },
    // {
    //   dataIndex: "source",
    //   title: "Subscription Source",
    //   filterType: {
    //     dbCol: "source",
    //     type: "string",
    //   },
    //   render: (record) => {
    //     return record !== null ? record : "N/A";
    //   },
    // },
    {
      dataIndex: "credits_allocated",
      title: "Credits Allocated",
      filterType: {
        dbCol: "credits_allocated",
        type: "number",
      },
      render: (record) => {
        return record !== null ? record : "N/A";
      },
    },
    {
      dataIndex: "credits_remaining",
      title: "Credits Remaining",
      filterType: {
        dbCol: "credits_remaining",
        type: "number",
      },
      render: (record) => {
        return record !== null ? record : "N/A";
      },
    },
    {
      dataIndex: "subscription_created_at",
      title: "Subscription Created At",
      filterType: {
        dbCol: "subscription_created_at",
        type: "date",
      },
      render: (record) => {
        return record !== null ? record.split(" ")[0] : `N/A`;
      },
    },
    {
      dataIndex: "subscription_expiry_date",
      title: "Subscription Expire At",
      filterType: {
        dbCol: "subscription_expiry_date",
        type: "date",
      },
      render: (record) => {
        return record !== null ? record : `N/A`;
      },
    },
    {
      title: "Actions",
      render: (record) => (
        <button
          className="btn btn-lg waves-effect waves-light register-button"
          onClick={() => {
            setUserRecord(record);
            setShowAdminCreateSubscription(true);
          }}
        >
          Create Subscription
        </button>
      ),
    },
  ];
  const params = {
    apiCallFn: async (queryParams) => {
      let data, error;
      await getUsers({ queryParams })
        .then(async (res) => {
          data = res.data;
        })
        .catch((err) => (error = err));

      return {
        data,
        error,
      };
    },
    deps: [usersRefreshKey],
    initialPageSize: 10,
    initialFilters: [],
    initialSortCriteria: null,
    config: {
      filtersKeyGen() {
        return "dt--users";
      },
      tableNum: 3,
    },
  };
  return <XestTable columns={columns} params={params} />;
};

export default UsersTable;
