import http from "utils/http";
import { subscriptions } from "constants/endpoints";

const postSubscriptions = ({
  subscriptionStatus,
  subscribedProduct,
  stripeMeta,
  subscriptionExpiryDate,
  additionalNotes,
  creditsAllocated,
  creditsRemaining,
  source,
  subscriptionType,
}) => {
  const POST_USER_ENDPOINT = subscriptions.postSubscriptions();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      subscriptionStatus,
      subscribedProduct,
      stripeMeta,
      subscriptionExpiryDate,
      additionalNotes,
      creditsAllocated,
      creditsRemaining,
      source,
      subscriptionType,
    },
  });
};

export default postSubscriptions;
