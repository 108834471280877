const COUNTRY_OPTIONS = [
  { key: 1, value: 1, label: "Afghanistan" },
  { key: 2, value: 2, label: "Albania" },
  { key: 3, value: 3, label: "Algeria" },
  { key: 4, value: 4, label: "Andorra" },
  { key: 5, value: 5, label: "Angola" },
  { key: 6, value: 6, label: "Antigua and Barbuda" },
  { key: 7, value: 7, label: "Argentina" },
  { key: 8, value: 8, label: "Armenia" },
  { key: 9, value: 9, label: "Australia" },
  { key: 10, value: 10, label: "Austria" },
  { key: 11, value: 11, label: "Azerbaijan" },
  { key: 12, value: 12, label: "Bahamas" },
  { key: 13, value: 13, label: "Bahrain" },
  { key: 14, value: 14, label: "Bangladesh" },
  { key: 15, value: 15, label: "Barbados" },
  { key: 16, value: 16, label: "Belarus" },
  { key: 17, value: 17, label: "Belgium" },
  { key: 18, value: 18, label: "Belize" },
  { key: 19, value: 19, label: "Benin" },
  { key: 20, value: 20, label: "Bhutan" },
  { key: 21, value: 21, label: "Bolivia" },
  { key: 22, value: 22, label: "Bosnia and Herzegovina" },
  { key: 23, value: 23, label: "Botswana" },
  { key: 24, value: 24, label: "Brazil" },
  { key: 25, value: 25, label: "Brunei" },
  { key: 26, value: 26, label: "Bulgaria" },
  { key: 27, value: 27, label: "Burkina Faso" },
  { key: 28, value: 28, label: "Burundi" },
  { key: 29, value: 29, label: "Cabo Verde" },
  { key: 30, value: 30, label: "Cambodia" },
  { key: 31, value: 31, label: "Cameroon" },
  { key: 32, value: 32, label: "Canada" },
  { key: 33, value: 33, label: "Central African Republic" },
  { key: 34, value: 34, label: "Chad" },
  { key: 35, value: 35, label: "Chile" },
  { key: 36, value: 36, label: "China" },
  { key: 37, value: 37, label: "Colombia" },
  { key: 38, value: 38, label: "Comoros" },
  { key: 39, value: 39, label: "Congo" },
  { key: 40, value: 40, label: "Costa Rica" },
  { key: 41, value: 41, label: "Cote d'Ivoire" },
  { key: 42, value: 42, label: "Croatia" },
  { key: 43, value: 43, label: "Cuba" },
  { key: 44, value: 44, label: "Cyprus" },
  { key: 45, value: 45, label: "Czech Republic" },
  { key: 46, value: 46, label: "Democratic Republic of the Congo" },
  { key: 47, value: 47, label: "Denmark" },
  { key: 48, value: 48, label: "Djibouti" },
  { key: 49, value: 49, label: "Dominica" },
  { key: 50, value: 50, label: "Dominican Republic" },
  { key: 51, value: 51, label: "Ecuador" },
  { key: 52, value: 52, label: "Egypt" },
  { key: 53, value: 53, label: "El Salvador" },
  { key: 54, value: 54, label: "Equatorial Guinea" },
  { key: 55, value: 55, label: "Eritrea" },
  { key: 56, value: 56, label: "Estonia" },
  { key: 57, value: 57, label: "Eswatini" },
  { key: 58, value: 58, label: "Ethiopia" },
  { key: 59, value: 59, label: "Fiji" },
  { key: 60, value: 60, label: "Finland" },
  { key: 61, value: 61, label: "France" },
  { key: 62, value: 62, label: "Gabon" },
  { key: 63, value: 63, label: "Gambia" },
  { key: 64, value: 64, label: "Georgia" },
  { key: 65, value: 65, label: "Germany" },
  { key: 66, value: 66, label: "Ghana" },
  { key: 67, value: 67, label: "Greece" },
  { key: 68, value: 68, label: "Grenada" },
  { key: 69, value: 69, label: "Guatemala" },
  { key: 70, value: 70, label: "Guinea" },
  { key: 71, value: 71, label: "Guinea-Bissau" },
  { key: 72, value: 72, label: "Guyana" },
  { key: 73, value: 73, label: "Haiti" },
  { key: 74, value: 74, label: "Honduras" },
  { key: 75, value: 75, label: "Hungary" },
  { key: 76, value: 76, label: "Iceland" },
  { key: 77, value: 77, label: "India" },
  { key: 78, value: 78, label: "Indonesia" },
  { key: 79, value: 79, label: "Iran" },
  { key: 80, value: 80, label: "Iraq" },
  { key: 81, value: 81, label: "Ireland" },
  { key: 82, value: 82, label: "Israel" },
  { key: 83, value: 83, label: "Italy" },
  { key: 84, value: 84, label: "Jamaica" },
  { key: 85, value: 85, label: "Japan" },
  { key: 86, value: 86, label: "Jordan" },
  { key: 87, value: 87, label: "Kazakhstan" },
  { key: 88, value: 88, label: "Kenya" },
  { key: 89, value: 89, label: "Kiribati" },
  { key: 90, value: 90, label: "Korea, North" },
  { key: 91, value: 91, label: "Korea, South" },
  { key: 92, value: 92, label: "Kosovo" },
  { key: 93, value: 93, label: "Kuwait" },
  { key: 94, value: 94, label: "Kyrgyzstan" },
  { key: 95, value: 95, label: "Laos" },
  { key: 96, value: 96, label: "Latvia" },
  { key: 97, value: 97, label: "Lebanon" },
  { key: 98, value: 98, label: "Lesotho" },
  { key: 99, value: 99, label: "Liberia" },
  { key: 100, value: 100, label: "Libya" },
  { key: 101, value: 101, label: "Liechtenstein" },
  { key: 102, value: 102, label: "Lithuania" },
  { key: 103, value: 103, label: "Luxembourg" },
  { key: 104, value: 104, label: "Madagascar" },
  { key: 105, value: 105, label: "Malawi" },
  { key: 106, value: 106, label: "Malaysia" },
  { key: 107, value: 107, label: "Maldives" },
  { key: 108, value: 108, label: "Mali" },
  { key: 109, value: 109, label: "Malta" },
  { key: 110, value: 110, label: "Marshall Islands" },
  { key: 111, value: 111, label: "Mauritania" },
  { key: 112, value: 112, label: "Mauritius" },
  { key: 113, value: 113, label: "Mexico" },
  { key: 114, value: 114, label: "Micronesia" },
  { key: 115, value: 115, label: "Moldova" },
  { key: 116, value: 116, label: "Monaco" },
  { key: 117, value: 117, label: "Mongolia" },
  { key: 118, value: 118, label: "Montenegro" },
  { key: 119, value: 119, label: "Morocco" },
  { key: 120, value: 120, label: "Mozambique" },
  { key: 121, value: 121, label: "Myanmar" },
  { key: 122, value: 122, label: "Namibia" },
  { key: 123, value: 123, label: "Nauru" },
  { key: 124, value: 124, label: "Nepal" },
  { key: 125, value: 125, label: "Netherlands" },
  { key: 126, value: 126, label: "New Zealand" },
  { key: 127, value: 127, label: "Nicaragua" },
  { key: 128, value: 128, label: "Niger" },
  { key: 129, value: 129, label: "Nigeria" },
  { key: 130, value: 130, label: "North Macedonia" },
  { key: 131, value: 131, label: "Norway" },
  { key: 132, value: 132, label: "Oman" },
  { key: 133, value: 133, label: "Pakistan" },
  { key: 134, value: 134, label: "Palau" },
  { key: 135, value: 135, label: "Palestine" },
  { key: 136, value: 136, label: "Panama" },
  { key: 137, value: 137, label: "Papua New Guinea" },
  { key: 138, value: 138, label: "Paraguay" },
  { key: 139, value: 139, label: "Peru" },
  { key: 140, value: 140, label: "Philippines" },
  { key: 141, value: 141, label: "Poland" },
  { key: 142, value: 142, label: "Portugal" },
  { key: 143, value: 143, label: "Qatar" },
  { key: 144, value: 144, label: "Romania" },
  { key: 145, value: 145, label: "Russia" },
  { key: 146, value: 146, label: "Rwanda" },
  { key: 147, value: 147, label: "Saint Kitts and Nevis" },
  { key: 148, value: 148, label: "Saint Lucia" },
  { key: 149, value: 149, label: "Saint Vincent and the Grenadines" },
  { key: 150, value: 150, label: "Samoa" },
  { key: 151, value: 151, label: "San Marino" },
  { key: 152, value: 152, label: "Sao Tome and Principe" },
  { key: 153, value: 153, label: "Saudi Arabia" },
  { key: 154, value: 154, label: "Senegal" },
  { key: 155, value: 155, label: "Serbia" },
  { key: 156, value: 156, label: "Seychelles" },
  { key: 157, value: 157, label: "Sierra Leone" },
  { key: 158, value: 158, label: "Singapore" },
  { key: 159, value: 159, label: "Slovakia" },
  { key: 160, value: 160, label: "Slovenia" },
  { key: 161, value: 161, label: "Solomon Islands" },
  { key: 162, value: 162, label: "Somalia" },
  { key: 163, value: 163, label: "South Africa" },
  { key: 164, value: 164, label: "South Sudan" },
  { key: 165, value: 165, label: "Spain" },
  { key: 166, value: 166, label: "Sri Lanka" },
  { key: 167, value: 167, label: "Sudan" },
  { key: 168, value: 168, label: "Suriname" },
  { key: 169, value: 169, label: "Sweden" },
  { key: 170, value: 170, label: "Switzerland" },
  { key: 171, value: 171, label: "Syria" },
  { key: 172, value: 172, label: "Taiwan" },
  { key: 173, value: 173, label: "Tajikistan" },
  { key: 174, value: 174, label: "Tanzania" },
  { key: 175, value: 175, label: "Thailand" },
  { key: 176, value: 176, label: "Timor-Leste" },
  { key: 177, value: 177, label: "Togo" },
  { key: 178, value: 178, label: "Tonga" },
  { key: 179, value: 179, label: "Trinidad and Tobago" },
  { key: 180, value: 180, label: "Tunisia" },
  { key: 181, value: 181, label: "Turkey" },
  { key: 182, value: 182, label: "Turkmenistan" },
  { key: 183, value: 183, label: "Tuvalu" },
  { key: 184, value: 184, label: "Uganda" },
  { key: 185, value: 185, label: "Ukraine" },
  { key: 186, value: 186, label: "United Arab Emirates" },
  { key: 187, value: 187, label: "United Kingdom" },
  { key: 188, value: 188, label: "United States" },
  { key: 189, value: 189, label: "Uruguay" },
  { key: 190, value: 190, label: "Uzbekistan" },
  { key: 191, value: 191, label: "Vanuatu" },
  { key: 192, value: 192, label: "Vatican City" },
  { key: 193, value: 193, label: "Venezuela" },
  { key: 194, value: 194, label: "Vietnam" },
  { key: 195, value: 195, label: "Yemen" },
  { key: 196, value: 196, label: "Zambia" },
  { key: 197, value: 197, label: "Zimbabwe" },
];

export default COUNTRY_OPTIONS;
