import http from "utils/http";
import { users } from "constants/endpoints";

const postRegistrationRequest = ({
  email,
  organizationId,
  invitation,
  firstName,
  lastName,
  password,
  phoneNumber,
  country,
  captcha,
  shortCode,
  invitationId,
  companyName,
  discountCode,
  source,
}) => {
  const ENDPOINT = users.postRegistrationRequest({
    invitedOrganizationId: organizationId,
    shortCode,
    invitationId,
  });
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${ENDPOINT}`, {
    data: {
      email,
      invitation,
      firstName,
      lastName,
      password,
      phoneNumber,
      country,
      captcha,
      companyName,
      discountCode,
      source,
    },
  });
};

export default postRegistrationRequest;
